<template>
      <login-form />
</template>

<script>
  import LoginForm from '../components/LoginForm'
export default {
   name: 'Login',
   props: {
      source: String,
  },
   components: {
      LoginForm,
    },
};
</script>

<style></style>
