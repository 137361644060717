<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar flat color="white" height="200px" class="py-2" align-center justify-center d-flex>
              <img
                src="https://res.cloudinary.com/venueperu/image/upload/v1662669321/cloud/clients/Y2x0LWxvZ29fcG9zaXRpdmUtMTI3MA%3D%3D.png"
                alt="logo"
                style="width: 100%; height: 100%; object-fit: contain"
              />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="mdi-account"
                name="login"
                label="Usuario"
                type="text"
                v-model="username"
                @keyup.enter="login"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Contraseña"
                type="password"
                v-model="password"
                @keyup.enter="login"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" block @click="login">Iniciar sesión</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { http } from "../plugins/axios";
import { STAND_EVENT_ID } from "../../.environment";
import { mutations } from "../plugins/store";

export default {
  name: "LoginForm",
  props: {
    source: String,
  },
  data: () => ({
    username: "",
    password: "",
  }),
  methods: {
    login() {
      const params = {
        event_id: STAND_EVENT_ID,
        username: this.username,
        password: this.password,
      };
      http
        .post("login", params)
        .then((res) => res.data)
        .then((res) => {
          mutations.setUserId(res.data.stand_user_id);
          mutations.setUserProfile(res.data.profile);
          this.$router.push("/");
        })
        .catch((e) => {
          alert(e.response.data.message);
        });
    },
  },
};
</script>

<style></style>
